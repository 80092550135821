export default {
  CLICKED_ON_REMOVE_FAILED_TRANSFERS_BUTTON: 'CLICKED_ON_REMOVE_FAILED_TRANSFERS_BUTTON',
  CREATED_PDF_STATEMENT_BATCH_REPORT: 'CREATED_PDF_STATEMENT_BATCH_REPORT',
  CREATED_XLSX_STATEMENT_ENTRIES_REPORT: 'CREATED_XLSX_STATEMENT_ENTRIES_REPORT',
  CREATED_XLSX_V2_STATEMENT_ENTRIES_REPORT: 'CREATED_XLSX_V2_STATEMENT_ENTRIES_REPORT',
  CREATED_CSV_STATEMENT_ENTRIES_REPORT: 'CREATED_CSV_STATEMENT_ENTRIES_REPORT',
  CREATED_OFX_STATEMENT_ENTRIES_REPORT: 'CREATED_OFX_STATEMENT_ENTRIES_REPORT',
  DOWNLOADED_BATCH_RECEIPT_BILLET: 'DOWNLOADED_BATCH_RECEIPT_BILLET',
  DOWNLOADED_BATCH_RECEIPT_TRANSFER: 'DOWNLOADED_BATCH_RECEIPT_TRANSFER',
  HAS_FILLED_FIRST_STEPS: 'HAS_FILLED_FIRST_STEPS',
  IMPORTED_SHEET_WITH_FAILED_TRANSFERS: 'IMPORTED_SHEET_WITH_FAILED_TRANSFERS',
  OPENED_BILLET_CREATION_FROM_BILLETS_PAGE: 'OPENED_BILLET_CREATION_FROM_BILLETS_PAGE',
  OPENED_BILLET_FROM_BILLETS_PAGE: 'OPENED_BILLET_FROM_BILLETS_PAGE',
  OPENED_DBA_MANAGEMENT: 'OPENED_DBA_MANAGEMENT',
  OPENED_TRANSFER_CREATION_FROM_TRANSFERS_PAGE: 'OPENED_TRANSFER_CREATION_FROM_TRANSFERS_PAGE',
  OPENED_TRANSFER_FROM_TRANSFERS_PAGE: 'OPENED_TRANSFER_FROM_TRANSFERS_PAGE',
  PIX_KEY_REGISTRATION_CTA_CLICKED: 'PIX_KEY_REGISTRATION_CTA_CLICKED',
  PIX_KEY_REGISTRATION_DISMISSED: 'PIX_KEY_REGISTRATION_DISMISSED',
  TRANSFER_FAIL_CORRECTION_CHANGING_DATA: 'TRANSFER_FAIL_CORRECTION_CHANGING_DATA',
  TRANSFER_FAIL_CORRECTION_CHANGING_DATA_VALID_DBA: 'TRANSFER_FAIL_CORRECTION_CHANGING_DATA_VALID_DBA',
  DBA_AUTOCOMPLETE_SELECTED: 'DBA_AUTOCOMPLETE_SELECTED',
  CONFIRM_BATCH_CLOSE: 'CONFIRM_BATCH_CLOSE',
  CONFIRM_BATCH_CLOSE_AFTER_SEND_BALANCE: 'CONFIRM_BATCH_CLOSE_AFTER_SEND_BALANCE',
  SEND_BATCH_TO_APPROVAL: 'SEND_BATCH_TO_APPROVAL',
  RECEIVERS_SEARCH_VIEWED: 'RECEIVERS_SEARCH_VIEWED',
  CONTACERTA_KNOW_MORE_CLICKED: 'CONTACERTA_KNOW_MORE_CLICKED',
  PAYIN_TOUR_VIEWED: 'PAYIN_TOUR_VIEWED',
  PAYIN_DASHBOARD_TOUR_VIEWED: 'PAYIN_DASHBOARD_TOUR_VIEWED',
  USER_HAS_SUCCESSFULLY_LOGGED_IN: 'USER_HAS_SUCCESSFULLY_LOGGED_IN',
  PAYIN_REPORT_DOWNLOADED: 'PAYIN_REPORT_DOWNLOADED',
  CARD_RECEIVABLE_REPORT_DOWNLOADED: 'CARD_RECEIVABLE_REPORT_DOWNLOADED',
  PAYIN_REFUNDED: 'PAYIN_REFUNDED',
  PAYIN_PAGE_VIEWED: 'PAYIN_PAGE_VIEWED',
  PAYMENT_LINK_LIST_PAGE_VIEWED: 'PAYMENT_LINK_LIST_PAGE_VIEWED',
  PAYMENT_LINK_CREATION_PAGE_VIEWED: 'PAYMENT_LINK_CREATION_PAGE_VIEWED',
  PAYMENT_LINK_ACCESSED_THROUGH_PAYINS: 'PAYMENT_LINK_ACCESSED_THROUGH_PAYINS',
  PAYMENT_LINK_ACCESSED_PAYMENT_LINK_LIST: 'PAYMENT_LINK_ACCESSED_PAYMENT_LINK_LIST',
  PAYMENT_LINK_CREATE_CLICKED: 'PAYMENT_LINK_CREATE_CLICKED',
  PAYMENT_LINK_COPIED_ON_PAYMENT_LINK_TABLE: 'PAYMENT_LINK_COPIED_ON_PAYMENT_LINK_TABLE',
  PAYMENT_LINK_COPIED_ON_PAYMENT_LINK_SUCCESS_MODAL: 'PAYMENT_LINK_COPIED_ON_PAYMENT_LINK_SUCCESS_MODAL',
  PAYMENT_LINK_DELETE_CLICKED: 'PAYMENT_LINK_DELETE_CLICKED',
  PAYMENT_LINK_CREATED_LINK_USING_BOLETO: 'PAYMENT_LINK_CREATED_LINK_USING_BOLETO',
  PAYMENT_LINK_CREATED_LINK_USING_PIX: 'PAYMENT_LINK_CREATED_LINK_USING_PIX',
  PAYMENT_LINK_CREATED_LINK_USING_CREDIT_CARD: 'PAYMENT_LINK_CREATED_LINK_USING_CREDIT_CARD',
  PAYMENT_LINK_CREATED_LINK_USING_PIX_AND_BOLETO: 'PAYMENT_LINK_CREATED_LINK_USING_PIX_AND_BOLETO',
  PAYMENT_LINK_CREATED_WITH_PIX_AND_BOLETO_AND_CARD: 'PAYMENT_LINK_CREATED_WITH_PIX_AND_BOLETO_AND_CARD',
  CREDIT_CARD_ACCEPTED_TERMS_OF_USE: 'CREDIT_CARD_ACCEPTED_TERMS_OF_USE',
  ADMIN_USING_TWO_FACTOR_WITH_APP: 'ADMIN_USING_TWO_FACTOR_WITH_APP',
  CONFIRM_BATCH_CANCEL: 'CONFIRM_BATCH_CANCEL',
  INFRACTION_CONTESTATION_TERMS_OF_USE: 'INFRACTION_CONTESTATION_TERMS_OF_USE',
  INFRACTION_CONTESTATION_PAGE_VIEWED: 'INFRACTION_CONTESTATION_VIEWED'
};
