import { computed, nextTick, reactive, ref, watch } from 'vue';

const useCursorPagination = (onChange = () => {}) => {
  const pagination = reactive({ previous: null, next: null, pageCursor: 'enabled', pageSize: 10 });

  const resetPagination = () => {
    pagination.pageCursor = 'enabled';
  };

  watch([() => pagination.pageCursor], () => {
    onChange();
  });

  return {
    resetPagination,
    pagination
  };
};

const useOffsetPagination = (onChange = () => {}) => {
  const pagination = reactive({ totalItems: null, itemsPerPage: 10, currentPage: 0 });

  const onPageChange = page => {
    pagination.currentPage = page;
  };

  const resetPagination = () => {
    pagination.currentPage = 0;
  };

  watch([() => pagination.currentPage], () => {
    onChange();
  });

  return {
    resetPagination,
    onPageChange,
    pagination
  };
};

export const usePagination = (onChange = () => {}) => {
  const cursorPagination = useCursorPagination(onChange);
  const offsetPagination = useOffsetPagination(onChange);
  const currentPagination = ref('cursor');

  const resetPagination = () => {
    cursorPagination.resetPagination();
    offsetPagination.resetPagination();
  };

  const switchToOffsetPagination = async () => {
    currentPagination.value = 'offset';
    await nextTick();
  };

  const isOffsetPagination = computed(() => currentPagination.value === 'offset');

  return {
    isOffsetPagination,
    currentPagination,
    cursorPagination,
    offsetPagination,
    resetPagination,
    switchToOffsetPagination
  };
};
