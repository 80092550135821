const { applications } = require('@transfeeradev/api-enums');

const APPS = {
  CONTACERTA: {
    title: 'ContaCerta',
    path: '/contacerta',
    key: applications.CONTACERTA
  },
  PAYMENTS: {
    title: 'Pagamentos',
    path: '/pagamentos',
    key: applications.APP
  },
  MY_ACCOUNT: {
    path: '/minha-conta',
    title: 'Minha conta'
  },
  MY_COMPANY: {
    path: '/minha-empresa',
    title: 'Minha empresa'
  },
  DEVELOPER_AREA: {
    path: '/area-do-desenvolvedor',
    title: 'Área do desenvolvedor'
  },
  PAYINS: {
    path: '/recebimentos',
    title: 'Recebimentos'
  },
  CONTESTATIONS: {
    path: '/contestacoes',
    title: 'Contestações'
  }
};

module.exports = {
  APPS
};
