
<template>
  <tr-table>
    <tr-thead>
      <tr-tr>
        <tr-th>
          <input
            id="select-all"
            v-model="selectAll"
            type="checkbox"
            class="cursor-pointer"
            @click.stop="selectAll = !selectAll"
          >
        </tr-th>
        <tr-th>E2E Pix</tr-th>
        <tr-th>Recebido em</tr-th>
        <tr-th>Tempo de Resposta</tr-th>
        <tr-th>Status da Constestação</tr-th>
        <tr-th>Parecer Transfeera</tr-th>
        <tr-th>Status Devolução</tr-th>
        <tr-th />
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="infraction in infractionsList"
        :key="infraction.id"
      >
        <tr-td>
          <input
            v-model="infraction.selected"
            type="checkbox"
            :disabled="infraction.analysis_status !== 'pending'"
          >
        </tr-td>
        <tr-td>
          <!-- Id da Transação -->
          <div
            style="display: inline-flex; align-items: center; gap: 4px;"
          >
            <bg-text>
              {{ middleTruncateWithEllipsis(infraction.transaction_id, 8) }}
            </bg-text>
            <copy-clipboard-button
              type="inline"
              :text-to-copy="infraction.transaction_id"
            />
          </div>
        </tr-td>
        <tr-td>
          <!-- Data da Infração -->
          <bg-text>
            {{ formatDate(infraction.infraction_date) }}
          </bg-text>
        </tr-td>
        <tr-td>
          <!-- Tempo de Resposta -->
          <bg-text class="response-badge">
            <span :class="getTimeClass(daysSinceInfraction(infraction.infraction_date))">{{ daysSinceInfraction(infraction.infraction_date) }}</span>
          </bg-text>
        </tr-td>
        <tr-td>
          <!-- Status da Contestação -->
          <bg-badge
            size="sm"
            :color="analysisStatusColor[infraction.analysis_status]?.badgeColor || 'grey-light'"
          >
            <bg-text
              :color="analysisStatusColor[infraction.analysis_status]?.textColor || 'grey-semi-dark'"
            >
              {{ statusDictionary[infraction.analysis_status] || infraction.analysis_status || '-' }}
            </bg-text>
          </bg-badge>
        </tr-td>
        <tr-td>
          <!-- Parecer Transfeera -->
          <bg-text>
            {{ infraction.analysis_description ? truncateString(infraction.analysis_description, 96) : '-' }}
          </bg-text>
        </tr-td>
        <tr-td>
          <!-- Status Devolução -->
          <bg-text>
            {{ infraction.refund.status ? infraction.refund.status : '-' }}
          </bg-text>
        </tr-td>
        <tr-td>
          <bg-text
            class="btn-flex"
          >
            <bg-button
              size="sm"
              :disabled="infraction.analysis_status !== 'pending'"
              @click="$event => analyze(index)"
            >
              Analisar
            </bg-button>
          </bg-text>
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script>
import { ref, reactive, watch, computed } from 'vue';
import { BgButton, BgText, BgBadge } from '@transfeeradev/bridge';
import CopyClipboardButton from '@/commons/components/container/CopyClipboardButton.vue';
import moment from '@transfeeradev/moment';
import { middleTruncateWithEllipsis, truncateString } from '@/commons/formatters/string';

export default {
  components: {
    BgButton,
    BgText,
    BgBadge,
    CopyClipboardButton
  },
  props: {
    infractionsList: {
      type: Array,
      required: true
    },
    loading: Boolean
  },
  setup(props) {
    const selectAll = ref(false);
    const filters = reactive({
      status: '',
      month: '',
      search: ''
    });
    const selectedStatusFilter = ref('Status da Contestação');
    const selectedMonthFilter = ref('Mês de Recebimento da Infração');

    const statusDictionary = reactive({
      pending: 'Pendente',
      accepted: 'Aceito',
      rejected: 'Rejeitado',
      delayed: 'Atrasado',
      canceled: 'Cancelado'
    });

    const analysisStatusColor = computed(() => ({
      pending: { textColor: 'yellow-semi-dark', badgeColor: 'yellow-light' },
      accepted: { textColor: 'green-semi-dark', badgeColor: 'green-light' },
      rejected: { textColor: 'blue-semi-dark', badgeColor: 'blue-light' },
      delayed: { textColor: 'red-semi-dark', badgeColor: 'red-light' },
      canceled: { textColor: 'grey-semi-dark', badgeColor: 'grey-light' }
    }));

    watch(selectAll, enabled => {
      props.infractionsList.forEach(infraction => {
        if (infraction.analysis_status === 'pending') {
          infraction.selected = enabled;
        }
      });
    });

    const daysSinceInfraction = infractionDate => {
      const infractionMoment = moment(infractionDate);
      const now = moment();
      const duration = moment.duration(now.diff(infractionMoment));

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      return hours > 72 ? '-' : `${hours}h${minutes}m`;
    };

    const formatDate = date => moment(date).format('DD/MM/YYYY');

    const getTimeClass = time => {
      if (time === '-') {
        return '';
      }
      const hours = parseInt(time.split('h')[0], 10);
      return hours <= 100 ? 'time-ok' : 'time-warning';
    };

    const analyze = index => {
      alert(`Analisando infração da linha ${index + 1}`);
    };

    const toggleSelectAll = () => {
      selectAll.value = !selectAll.value;
    };

    return {
      selectAll,
      filters,
      selectedStatusFilter,
      selectedMonthFilter,
      statusDictionary,
      analysisStatusColor,
      daysSinceInfraction,
      formatDate,
      getTimeClass,
      analyze,
      toggleSelectAll,
      middleTruncateWithEllipsis,
      truncateString
    };
  }
};
</script>

<style scoped>
.table-container {
  margin: auto;
  max-width: 1281px;
}

.time-ok {
  color: #ff4d4f;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}

.time-warning {
  font-weight: bold;
  color: #ffa500;
  padding: 5px;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.pagination {
  text-align: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
}

.response-badge {
  display: flex;
  width: 100%;
  justify-content: center;
}

.btn-flex {
  display: flex;
  justify-content: center;
}
</style>
