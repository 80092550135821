import infractionsResource from '@/apps/contestations/infractions/resources/infractions';

export async function listInfractions({
  pageCursor,
  pageSize,
  infractionStartDate,
  infractionEndDate,
  searchTerm,
  infractionAnalysisStatus
}) {
  const response = await infractionsResource.getInfractionsList({
    page_cursor: pageCursor,
    page_size: pageSize,
    analysis_status: Object.keys(infractionAnalysisStatus)
      .filter(key => infractionAnalysisStatus[key])
      .join(','),
    transaction_id: searchTerm,
    payer_tax_id: searchTerm,
    infraction_date__gte: infractionStartDate ? infractionStartDate.toISOString().split('T')[0] : null,
    infraction_date__lte: infractionEndDate ? infractionEndDate.toISOString().split('T')[0] : null
  });
  return response;
}

export async function batchAnalyzeInfractions(infractionIds, analysis) {
  const response = await infractionsResource.batchAnalysis(infractionIds, analysis);
  return response;
}
