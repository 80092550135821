import { payinsResource } from '@/commons/resources/payins';
import moment from '@transfeeradev/moment/src/plugins/moment-business-day';
import { safeSum } from '@/apps/payin/payins/helper/math';

export async function getSummary({ search, statuses = {}, paymentTypes = {}, startDate, endDate }) {
  const status__in = Object.entries(statuses)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const payment_type__in = Object.entries(paymentTypes)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const oneMonthDateFilterLimit = moment('2024-01-01');
  const isEndMonthBeforeLimit = moment(endDate).isBefore(oneMonthDateFilterLimit);
  const isStartMonthBeforeLimit = moment(startDate).isBefore(oneMonthDateFilterLimit);

  if (isEndMonthBeforeLimit) {
    return {
      canceled: 0,
      refunded: 0,
      pending: 0,
      received: 0,
      authorized: 0,
      unauthorized: 0
    };
  }

  const response = await payinsResource.getSummary({
    status__in,
    payment_type__in,
    search,
    created_at__gte: isStartMonthBeforeLimit ? moment('2024-01-01').toISOString() : moment(startDate).toISOString(),
    created_at__lte: moment(endDate).toISOString()
  });

  const {
    canceled_by_psp,
    canceled_by_user,
    refunded,
    received,
    pending,
    partially_refunded,
    authorized,
    unauthorized,
    canceled
  } = response;

  return {
    canceled: canceled_by_psp.total_amount + canceled_by_user.total_amount + canceled.total_amount,
    refunded: safeSum(refunded.total_amount, partially_refunded.total_amount),
    pending: pending.total_amount,
    received: received.total_amount,
    authorized: authorized.total_amount,
    unauthorized: unauthorized.total_amount
  };
}
