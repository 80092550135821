import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = `${config.url.api.transfeera}/med/infractions`;

export default {
  getInfractionsList: ({
    page_cursor,
    page_size = 10,
    analysis_status,
    transaction_id,
    payer_tax_id,
    infraction_date__gte,
    infraction_date__lte
  }) => {
    const query = new URLSearchParams({ page_size });

    for (const [key, value] of Object.entries({
      analysis_status,
      infraction_date__gte,
      infraction_date__lte,
      transaction_id,
      payer_tax_id,
      page_cursor
    })) {
      if (value) {
        query.append(key, value);
      }
    }
    return fetch(`${BASE_URL}?${query}`);
  },

  batchAnalysis: ({ infraction_ids, analysis }) => {
    const body = {
      infraction_ids,
      analysis
    };
    return fetch(`${BASE_URL}/analysis`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }
};
