<template>
  <router-view
    v-slot="{ Component }"
  >
    <component
      :is="Component"
      @loading="$emit('loading', $event)"
    />
  </router-view>
</template>

<script setup>
const emit = defineEmits(['loading']);

emit('loading', false);
</script>
