<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="infractions-batch-approve-dialog"
    title="Tem certeza que deseja aprovar as infrações selecionadas?"
    :has-its-own-buttons="true"
    :on-close="close"
  >
    <bg-text
      type="body-md"
      color="grey-dark"
    >
      Ao confirmar, as infrações selecionadas serão aprovadas e registradas como aceitas.
    </bg-text>
    <tr-dialog-buttons-container>
      <bg-button
        class="pull-left"
        variant="outline"
        @click="close()"
      >
        Voltar
      </bg-button>
      <bg-button
        size="md"
        class="pull-right"
        @click="approveSelectedInfractions()"
      >
        {{ isInfractionApproving ? 'Aprovando...' : `Aprovar` }}
      </bg-button>
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { BgText, BgButton } from '@transfeeradev/bridge';
import { batchAnalyzeInfractions } from '@/apps/contestations/infractions/services/infractions';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';

const emit = defineEmits(['approved']);
const { handleError } = useErrorHandler();

const props = defineProps({
  selectedInfractions: {
    type: Array,
    required: true
  }
});

const modal = ref();
const isInfractionApproving = ref(false);

const open = () => {
  modal.value.open();
};

const close = () => {
  modal.value.close();
};

const approveSelectedInfractions = async () => {
  isInfractionApproving.value = true;
  try {
    await batchAnalyzeInfractions({
      infraction_ids: props.selectedInfractions.map(infraction => infraction.id),
      analysis: 'accepted'
    });
    emit('approved');
  } catch (error) {
    handleError(error, 'Ocorreu um erro ao aprovar infrações pix');
  } finally {
    isInfractionApproving.value = false;
    close();
  }
};

defineExpose({
  open,
  close
});
</script>