<template>
  <bg-data-table
    is="main"
    class="page-wrapper"
  >
    <tr-page-header
      v-if="$slots.header"
      :title="title"
    >
      <template
        v-if="onFilterByPeriod"
        #center-filter
      >
        <div class="row indicator-page__date-filter">
          <div class="col-lg-10">
            <tr-month-picker-button :on-filter="onFilterByPeriod" />
          </div>
        </div>
      </template>
      <template #right-filter>
        <slot name="header-right" />
      </template>
    </tr-page-header>
    <slot name="header" />
    <div class="toolbar">
      <div class="page-filters">
        <div class="date-filter-container">
          <slot name="bg-date-filter" />
        </div>
        <bg-filters
          v-if="selectedFilters && $slots['bg-filters']"
          v-model="selectedFilters"
          :label="`Filtros ${filtersCount ? '(' + filtersCount + ')' : ''}`"
          @update:modelValue="submitFilters"
        >
          <slot name="bg-filters" />
        </bg-filters>
        <tr-form
          name="searchForm"
          :on-valid-submit="submitFilters"
        >
          <tr-text-field
            v-model="searchTerm"
            v-tooltip="searchPlaceholder"
            name="search"
            classes="col-lg-12"
            no-margin="true"
            :placeholder="searchPlaceholder"
            input-search="true"
          />
        </tr-form>
      </div>
      <slot name="toolbar-buttons" />
    </div>
    <button
      v-if="filtersCount"
      class="reset-button page-reset-filters-button"
      @click="onCleanFilter"
    >
      <bg-text
        is="u"
        weight="bold"
        type="body-sm"
      >
        Limpar filtros
      </bg-text>
    </button>
    <tr-loading
      v-if="loading"
      classes="center-h-and-v width-100"
      style="min-height: 50vh"
    />
    <slot
      v-else
      name="content"
    />
    <template #pagination>
      <div class="pagination-wrapper">
        <div class="pagination-container">
          <slot name="pagination" />
        </div>
        <div
          v-if="$slots.pagination"
          class="items-per-page-container"
        >
          <slot name="items-per-page" />
        </div>
      </div>
    </template>
  </bg-data-table>
</template>

<script setup>
import { BgText, BgFilters, BgDataTable } from '@transfeeradev/bridge';
import { useDebounceFn } from '@vueuse/core';
import { useSlots } from 'vue';
import { computed } from 'vue';

const props = defineProps({
  loading: {
    type: Boolean
  },
  onFilterByPeriod: {
    type: Function
  },
  onCleanFilter: {
    type: Function,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  searchPlaceholder: {
    type: String,
    default: 'Buscar'
  }
});

const slots = useSlots();

if (slots['bg-filters'] && !props.onCleanFilter) {
  throw new Error('You must provide a onCleanFilter function when using the bg-filters slot');
}

const emit = defineEmits(['submit-filters', 'filter-by-period']);
const searchTerm = defineModel('searchTerm', { type: String });
const selectedFilters = defineModel('selectedFilters', { type: Object, required: true });

const filtersCount = computed(() => {
  return Object.values(selectedFilters.value || {})
    .map(values => Object.values(values).filter(selected => selected).length)
    .reduce((acc, curr) => acc + curr, 0);
});

const submitFilters = useDebounceFn((...e) => {
  emit('submit-filters', ...e);
}, 500);
</script>

<style scoped>
.page-wrapper {
  margin-bottom: 40px;

  &:deep(table) {
    margin: 0;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-filters {
  display: flex;
  align-items: flex-end;
  margin-top: var(--bg-spacing-lg);
  gap: var(--bg-spacing-sm);

  > form[name='searchForm'] > div {
    padding: 0;
  }

  .date-filter-container {
    min-width: 248px;
  }
}

.page-reset-filters-button {
  width: fit-content;
  color: var(--bg-blue-normal);
  margin-top: var(--bg-spacing-sm);
}

.page-summary__container {
  display: flex;
  flex-direction: row;
  gap: var(--bg-spacing-sm);
}

.page-summary__container :deep(.bg-card) {
  box-shadow: none;
}

.pagination-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: var(--bg-spacing-sm);
}

.pagination-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.items-per-page-container {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}
</style>
