<template>
  <page title="">
    <div class="container">
      <bg-stack
        scale="sm"
        class="content-section"
      >
        <bg-text
          type="h4"
          class="section-title"
          color="grey-dark"
        >
          Descubra o novo painel de infrações
        </bg-text>

        <bg-text
          type="body-md"
        >
          No painel de infrações você terá uma visão clara das contestações de pagamentos realizados por Pix para sua empresa.
        </bg-text>
        <bg-text
          type="body-md"
        >
          Oferecemos todas as informações sobre notificações de infração em um ambiente seguro e de fácil controle, melhorando sua operação!
        </bg-text>
      </bg-stack>

      <bg-stack
        scale="sm"
        class="content-section"
      >
        <bg-text
          type="h5"
          class="section-title"
          color="grey-dark"
        >
          Como responder uma notificação de infração
        </bg-text>

        <ul>
          <li>Comprovar a realização da venda.</li>
          <li>Obrigatório identificar o produto ou serviço comercializado, com evidências.</li>
          <li>Detalhar o site utilizado para a venda.</li>
          <li>Confirmar sua entrega.</li>
          <li>Identificar o merchant.</li>
          <li>Análise de riscos das transações do merchant concluindo se a venda é legítima ou não.</li>
        </ul>

        <bg-text type="body-md">
          Caso queira enviar prints ou arquivos de imagem como suporte, todos os arquivos devem ser reunidos em um único PDF;
          nós aceitamos apenas um anexo por resposta.
        </bg-text>

        <bg-text type="body-md">
          As notificações devem ser respondidas separadamente, uma vez que cada uma representa uma contestação individual da
          transação e não está vinculada a outras, mesmo que sejam do mesmo titular.
        </bg-text>

        <bg-text type="body-md">
          O prazo para resposta é de 3 dias úteis a partir do momento de recebimento da notificação (se a notificação for recebida
          às 8h, o prazo se encerra às 8h do terceiro dia útil). Após esse período, não será mais possível responder, e os valores
          serão automaticamente devolvidos ao pagador.
        </bg-text>
      </bg-stack>

      <bg-stack
        scale="sm"
        class="content-section"
      >
        <bg-text
          type="h5"
          class="section-title"
          color="grey-dark"
        >
          Resumo das Regras:
        </bg-text>

        <ul>
          <li>Cada infração deve ser respondida de forma individual.</li>
          <li>Após o envio da defesa, não é possível alterá-la.</li>
          <li>Defesas incompletas ou sem informações relevantes levarão ao aceite da infração sem possibilidade de nova defesa.</li>
          <li>A Transfeera é responsável por julgar os casos e decidir se acata a defesa do cliente.</li>
          <li>O prazo de resposta é de 3 dias úteis a partir do recebimento da notificação (se recebida às 8h, o prazo termina às 8h do terceiro dia útil).</li>
          <li>Notificações com prazo de resposta expirado serão automaticamente devolvidas ao pagador, sem direito de defesa.</li>
        </ul>
      </bg-stack>

      <bg-stack>
        <input
          id="accept-terms"
          v-model="acceptTerms"
          type="checkbox"
          class="cursor-pointer"
        >
        <bg-text
          is="label"
          class="margin-left-10"
          for="accept-terms"
          type="body-md"
        >
          Ao clicar em confirmar, você aceita os termos de resposta de notificações de infração.
        </bg-text>
      </bg-stack>
      <bg-stack class="accept-terms-section__button">
        <bg-button
          :disabled="isUpdating || !acceptTerms"
          @click="acceptTermsOfUse"
        >
          {{ isUpdating ? 'Liberando acesso...' : 'Confirmar' }}
        </bg-button>
      </bg-stack>
    </div>
  </page>
</template>

<script setup>
import Page from '@/commons/components/presentational/Page.vue';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { ref } from 'vue';
import { useToaster } from '@/commons/composables/useToaster';
import { useRouter } from 'vue-router';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { BgText, BgButton } from '@transfeeradev/bridge';

const emit = defineEmits(['loading']);
const toaster = useToaster();
const router = useRouter();
const isUpdating = ref(false);
const acceptTerms = ref(false);
const { handleError } = useErrorHandler();

const acceptTermsOfUse = async () => {
  try {
    isUpdating.value = true;
    await checkpointResource.create(checkpoint.INFRACTION_CONTESTATION_TERMS_OF_USE);
    toaster.success('Painel de infrações está liberado e pronto para uso');
    await router.push({ name: 'InfractionsPage' });
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao habilitar o painel de infrações');
    isUpdating.value = false;
  }
};

emit('loading', false);
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.content-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.section-title {
  margin-bottom: 12px;
}

.accept-terms-section__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}
</style>