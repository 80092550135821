<template>
  <table-page
    v-model:search-term="searchTerm"
    v-model:selected-filters="selectedAnalysisStatusFilter"
    title="Contestações"
    :loading="isInfractionsLoading"
    :search-placeholder="'Documento ou ID (E2E Pix)'"
    @clean-filter="onCleanFilter"
    @submit-filters="submitFilter"
  >
    <template #bg-date-filter>
      <bg-date-filter
        v-model:start-date="period.startDate"
        v-model:end-date="period.endDate"
        full-width
        @update:start-date="submitFilter"
        @update:end-date="submitFilter"
      />
    </template>
    <template #toolbar-buttons>
      <bg-button
        v-if="infractionsList.length > 0"
        class="pull-right"
        size="md"
        :disabled="!infractionsList.some(infraction => infraction.selected)"
        @click="openInfractionsBatchApproveDialog"
      >
        Aprovar selecionados {{ selectedInfractions.length }}
      </bg-button>
    </template>
    <template #bg-filters>
      <bg-filter
        key="status"
        label="Status"
      >
        <bg-filter-option
          v-for="(statusName, statusKey) in analysisStatusOptions"
          :key="statusKey"
          :label="statusName"
        />
      </bg-filter>
    </template>
    <template #content>
      <infractions-list
        v-if="infractionsList.length > 0"
        :infractions-list="infractionsList"
      />
      <tr-empty-search-result
        v-if="!isInfractionsLoading && infractionsList.length === 0"
        text="Não há infrações a serem listadas"
      />
    </template>
    <template #pagination>
      <bg-pagination
        v-if="cursorPagination.pagination.next || cursorPagination.pagination.previous"
        v-model:page-cursor="cursorPagination.pagination.pageCursor"
        :next="cursorPagination.pagination.next"
        :previous="cursorPagination.pagination.previous"
      />
    </template>
    <template #items-per-page>
      <tr-button
        v-if="infractionsList.length > 0"
        dropdown="true"
        :label="selectedItemsPerPage.name"
        text-variant="body"
        variant="text"
      >
        <tr-dropdown-item
          v-for="option in itemsPerPageOptions"
          :key="option.key"
          @click="onSelectedItemsPerPage(option)"
        >
          {{ option.name }}
        </tr-dropdown-item>
      </tr-button>
    </template>
  </table-page>
  <infractions-batch-approve-dialog
    ref="infractionsBatchApproveDialog"
    :selected-infractions="selectedInfractions"
    @approved="fetchInfractions"
  />
</template>

<script setup>
import TablePage from '@/commons/components/presentational/TablePage.vue';
// eslint-disable-next-line no-unused-vars
import InfractionsList from '@/apps/contestations/infractions/presentational/InfractionsList.vue';
// eslint-disable-next-line no-unused-vars
import InfractionsBatchApproveDialog from '@/apps/contestations/infractions/presentational/InfractionsBatchApproveDialog.vue';
import { BgPagination, BgDateFilter, BgFilter, BgFilterOption, BgButton } from '@transfeeradev/bridge';
import { usePagination } from '@/apps/payin/payins/pages/usePagination';
import { listInfractions } from '@/apps/contestations/infractions/services/infractions';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import moment from '@transfeeradev/moment/src/plugins/moment-business-day';
import { computed } from 'vue';

const { handleError } = useErrorHandler();
const infractionsList = ref([]);

const searchTerm = ref('');
const period = ref({
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('month').toDate()
});

const infractionsBatchApproveDialog = ref();

const openInfractionsBatchApproveDialog = () => {
  infractionsBatchApproveDialog.value.open();
};

const selectedInfractions = computed(() => {
  return infractionsList.value.filter(infraction => infraction.selected);
});

const itemsPerPageOptions = [
  {
    key: '10',
    name: 'Mostrar 10 items'
  },
  {
    key: '20',
    name: 'Mostrar 20 items'
  },
  {
    key: '30',
    name: 'Mostrar 30 items'
  },
  {
    key: '50',
    name: 'Mostrar 50 items'
  },
  {
    key: '100',
    name: 'Mostrar 100 items'
  }
];

const analysisStatusOptions = {
  pending: 'Pendente',
  accepted: 'Aceito',
  rejected: 'Negado',
  delayed: 'Atrasado',
  canceled: 'Cancelado'
};

const selectedItemsPerPage = ref(itemsPerPageOptions.find(option => option.key === '10'));
const selectedAnalysisStatusFilter = ref({
  status: {}
});

const isInfractionsLoading = ref(false);

const onSelectedItemsPerPage = option => {
  selectedItemsPerPage.value = option;
  resetPagination();
  fetchInfractions();
};

const resetPagination = () => {
  cursorPagination.pagination.pageCursor = 'enabled';
};

const submitFilter = filters => {
  if (filters?.status) {
    selectedAnalysisStatusFilter.value.status = filters.status;
  }
  resetPagination();
  fetchInfractionsDebounced();
};

const onCleanFilter = () => {
  selectedAnalysisStatusFilter.value = { status: {} };
  resetPagination();
  fetchInfractions();
};

const { cursorPagination } = usePagination(() => {
  fetchInfractions();
});

const getPaginationData = () => {
  return {
    pageCursor: cursorPagination.pagination.pageCursor === 'enabled' ? null : cursorPagination.pagination.pageCursor,
    pageSize: selectedItemsPerPage.value.key
  };
};

const fetchInfractionsDebounced = useDebounceFn(() => {
  isInfractionsLoading.value = true;
  fetchInfractions();
}, 300);

const fetchInfractions = async () => {
  try {
    isInfractionsLoading.value = true;
    const data = await listInfractions({
      ...getPaginationData(),
      infractionStartDate: period.value.startDate,
      infractionEndDate: period.value.endDate,
      searchTerm: searchTerm.value,
      infractionAnalysisStatus: selectedAnalysisStatusFilter.value.status
    });
    infractionsList.value = data.items;
    cursorPagination.pagination.next = data.meta.next;
    cursorPagination.pagination.previous = data.meta.previous;
  } catch (error) {
    handleError(error, 'Ocorreu um erro ao buscar infrações Pix');
  } finally {
    isInfractionsLoading.value = false;
  }
};
fetchInfractions();
</script>
