<template>
  <slot v-if="show" />
</template>

<script setup>
import { hasFeatureFlag } from '@/commons/services/featureFlags';
import { ref } from 'vue';

const props = defineProps({
  flag: {
    type: String,
    required: true
  },
  variant: {
    type: String
  }
});

const show = ref(hasFeatureFlag(props.flag, props.variant));
</script>
