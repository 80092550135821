import config from '@/config';
import fetch from '@/fetch';
import { payinStatus } from '@/apps/payin/payins/constants/payinStatus';

const BASE_URL = config.url.api.payin + '/app';

export function normalizeStatus(statusIn) {
  const statuses = [];

  for (let filterStatus of statusIn) {
    statuses.push(filterStatus);

    switch (filterStatus) {
      case payinStatus.CANCELED:
        statuses.push(payinStatus.CANCELED_BY_USER, payinStatus.CANCELED_BY_PSP);
        break;
      case payinStatus.REFUNDED:
        statuses.push(payinStatus.PARTIALLY_REFUNDED);
        break;
    }
  }

  return statuses;
}

export const payinsResource = {
  /**
   * @returns {Promise<{
   * items: Payin[],
   * metadata: {pagination: {itemsPerPage: number, totalItems: number}}}>}
   */
  get: ({
    page,
    page_cursor,
    page_size = 10,
    status__in,
    payment_type__in,
    search,
    created_at__gte,
    created_at__lte
  }) => {
    const query = new URLSearchParams({ page_size });

    if (page_cursor) {
      query.append('page_cursor', page_cursor);
    }

    if (page) {
      query.append('page', page);
    }

    for (const [key, value] of Object.entries({
      search,
      created_at__gte,
      created_at__lte
    })) {
      if (value) {
        query.append(key, value);
      }
    }

    if (status__in) {
      const statuses = normalizeStatus(status__in);
      for (let filterStatus of statuses) {
        query.append('status__in', filterStatus);
      }
    }

    if (payment_type__in) {
      for (let filterPaymentType of payment_type__in) {
        query.append('payment_type__in', filterPaymentType);
      }
    }

    return fetch(`${BASE_URL}/payins?${query}`);
  },
  getById: id => {
    return fetch(`${BASE_URL}/payins/${id}`);
  },

  /** @returns {string} id */
  exportCSV: ({ created_at__gte, created_at__lte, search, status__in, payment_type__in } = {}) => {
    return fetch(`${BASE_URL}/payin_reports`, {
      method: 'POST',
      body: JSON.stringify({
        created_at__gte,
        created_at__lte,
        search,
        status__in: status__in ? normalizeStatus(status__in) : [],
        payment_type__in
      })
    });
  },

  /** @returns {Promise<{items: PayinReport[]}>} */
  getReportById: id => {
    return fetch(`${BASE_URL}/payin_reports/${id}`);
  },

  /** @returns {Promise<{result: PayinsSummary}>} */
  getSummary: ({ status__in, payment_type__in, search, created_at__gte, created_at__lte }) => {
    const query = new URLSearchParams();

    for (const [key, value] of Object.entries({
      search,
      created_at__gte,
      created_at__lte
    })) {
      if (value) {
        query.append(key, value);
      }
    }

    if (status__in) {
      const statuses = normalizeStatus(status__in);
      for (let filterStatus of statuses) {
        query.append('status__in', filterStatus);
      }
    }

    if (payment_type__in) {
      for (let filterPaymentType of payment_type__in) {
        query.append('payment_type__in', filterPaymentType);
      }
    }

    return fetch(`${BASE_URL}/payins_summary?${query}`);
  }
};
