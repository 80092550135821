<template>
  <div>
    <view-batch-with-any-waiting-status
      :batch="batch"
      :on-close="onClose"
    >
      <tr-show-for-roles :roles="appRoles.ADMIN">
        <tr-button
          class="pull-right"
          variant="raised"
          color="primary"
          label="Editar lote"
          :on-button-click="openBatchEditionConfirmationDialog"
        />
      </tr-show-for-roles>
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <tr-button
          class="pull-right margin-right"
          variant="raised"
          size="medium"
          svg-icon-name="ic-delete"
          svg-icon-color="white"
          color="danger"
          :on-button-click="openBatchRemovalConfirmationDialog"
        />
      </tr-hide-for-roles>
      <div class="row bottom-offset4">
        <tr-button
          classes="col-lg-12"
          :on-button-click="openBankAccountToSendBalanceDialog"
          variant="text"
          :normal-alignment="true"
          :link-color="true"
          text-variant="body"
          label="Ver dados bancários para o envio"
        />
      </div>
    </view-batch-with-any-waiting-status>
    <batch-edition-confirmation-dialog
      ref="batchEditionConfirmationDialog"
      :waiting-to-receive="true"
      :on-confirm="enableEdit"
    />
    <batch-removal-confirmation-dialog
      ref="batchRemovalConfirmationDialog"
      :on-confirm="removeBatch"
    />
    <entry-wizard-dialog
      ref="entryWizardDialog"
      :on-change="refreshTopbar"
    />
  </div>
</template>

<script>
import ViewBatchWithAnyWaitingStatus from '@/apps/payments/payments/batch/components/presentational/ViewBatchWithAnyWaitingStatus';
import BatchEditionConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchEditionConfirmationDialog';
import BatchRemovalConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchRemovalConfirmationDialog';
import EntryWizardDialog from '@/apps/payments/payments/statement/components/presentational/EntryWizardDialog.vue';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { roles } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'view-batch-to-receive-container',
  components: {
    ViewBatchWithAnyWaitingStatus,
    BatchEditionConfirmationDialog,
    BatchRemovalConfirmationDialog,
    EntryWizardDialog
  },
  props: {
    batch: {
      required: false,
      default: () => ({})
    },
    onClose: {
      required: false,
      type: Function
    },
    onRemove: {
      required: true,
      type: Function
    },
    onEdit: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    appRoles: roles.appRoles
  }),
  methods: {
    refreshTopbar() {
      window.dispatchEvent(new Event('topbar.refresh'));
    },
    openBatchRemovalConfirmationDialog() {
      this.$refs.batchRemovalConfirmationDialog.open();
    },
    openBatchEditionConfirmationDialog() {
      this.$refs.batchEditionConfirmationDialog.open();
    },
    openBankAccountToSendBalanceDialog() {
      this.$refs.entryWizardDialog.open();
    },
    removeBatch() {
      this.$refs.batchRemovalConfirmationDialog.close();

      return batchResource.remove(this.batch.id, false).then(
        () => {
          this.$toaster.success('Lote excluído com sucesso');
          window.dispatchEvent(new Event('topbar.refresh'));
          this.onRemove();
          if (this.onClose) {
            this.onClose();
          }
        },
        error => {
          this.$toaster.error(getMessage(error));
        }
      );
    },
    enableEdit() {
      this.$refs.batchEditionConfirmationDialog.close();

      return batchResource.enableEdit(this.batch.id).then(
        () => {
          window.dispatchEvent(new Event('topbar.refresh'));
          if (this.onClose) {
            this.onClose();
          }
          this.onEdit();
        },
        error => {
          this.$toaster.error(getMessage(error));
        }
      );
    }
  }
};
</script>
