import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { APPS } from '@/commons/constants/apps';
import { hasFeatureFlag } from '@/commons/services/featureFlags';
import Layout from './Layout';
import InfractionsPage from './infractions/pages/InfractionsPage.vue';
import InfractionContestationsTermsOfUsePage from './infractions/pages/InfractionContestationsTermsOfUsePage.vue';

export default {
  path: '/contestacoes',
  component: Layout,
  name: 'Contestações',
  redirect: {
    name: 'InfractionsPage'
  },
  beforeEnter: () => {
    checkpointResource.create(checkpoint.INFRACTION_CONTESTATION_PAGE_VIEWED);
  },
  children: [
    {
      path: 'infracoes/termos-de-uso',
      component: InfractionContestationsTermsOfUsePage,
      name: 'InfractionContestationsTermsOfUsePage',
      meta: {
        async beforeEnter() {
          const { exists: alreadyAccepted } = await checkpointResource.existsInCompany(
            checkpoint.INFRACTION_CONTESTATION_TERMS_OF_USE
          );

          if (alreadyAccepted) {
            return { name: 'InfractionsPage' };
          }
        }
      }
    },
    {
      path: 'infracoes',
      component: InfractionsPage,
      name: 'InfractionsPage',
      meta: {
        beforeEnter: [termsOfUseGuard, featureFlagGuard]
      }
    }
  ].map(route => ({
    ...route,
    meta: {
      ...route.meta,
      authAs: APPS.PAYMENTS.key
    }
  }))
};

async function termsOfUseGuard() {
  const { exists: alreadyAccepted } = await checkpointResource.existsInCompany(
    checkpoint.INFRACTION_CONTESTATION_TERMS_OF_USE
  );

  if (!alreadyAccepted) {
    return { name: 'InfractionContestationsTermsOfUsePage' };
  }
}

function featureFlagGuard() {
  const medPanelFeatureFlag = hasFeatureFlag('med-panel');

  if (!medPanelFeatureFlag) {
    return '/';
  }
}
